import { template as template_c7b6b7f44fc3435087c3924e653da26a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c7b6b7f44fc3435087c3924e653da26a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
