import { template as template_7d28fe7dfef141bab07a0b1d2f7699a8 } from "@ember/template-compiler";
const FKLabel = template_7d28fe7dfef141bab07a0b1d2f7699a8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
