import { template as template_df7d0fbc05c14ed68d35e0bba149b775 } from "@ember/template-compiler";
const SidebarSectionMessage = template_df7d0fbc05c14ed68d35e0bba149b775(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
